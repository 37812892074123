<template>
  <div class=" container-fluid container-fixed-lg">
    <div class="card card-transparent">
      <div class="card-header d-flex justify-content-between">
        <div class="page-title card-title">
          {{ $t('taxi.info') }}
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-10">
            <div data-pages="card" class="card card-default">
              <div class="card-header">
              </div>
              <div class="card-body">
                <ValidationObserver ref="form_update_basic_info" v-slot="{ passes, valid, validated }">
                  <form novalidate autocomplete="off" @submit.prevent="passes(updateInfo)">
                    <ValidationObserver ref="code" v-slot="{ passes, valid, validated }">
                      <AppInput hidden name="code_force_reject"
                                v-model="validate.code_force_reject"></AppInput>
                      <AppInput name="code" cleave-regex="[^a-zA-Z]" :label="$t('taxi.code')"
                                type="string-uppercase-length:2"
                                placeholder="アルファベット２文字以内"
                                rules="required|booking_code:@code_force_reject"
                                v-model="entry.code"/>
                    </ValidationObserver>
                    <AppInput name="name" :label="$t('taxi.taxi name')" type="string-length:30" rules="required"
                              v-model="entry.name"/>
                    <AppInput name="phone" :label="$t('common.phone')" type="number-length:11" rules="required|phone"
                              v-model="entry.phone"/>
                    <AppInput name="address" :label="$t('common.address')" type="string" rules="required"
                              v-model="entry.address"/>
                    <AppInput name="fax" :label="$t('common.fax')" type="number-length:10" rules="fax"
                              v-model="entry.fax"/>
                    <AppInput name="kidchair_num_default" :label="$t('taxi.kidchair_num_default')" type="int"
                              rules="required"
                              v-model="entry.kidchair_num_default"/>
                    <div class="app-form-group row form-group">
                      <label class="col-md-4 input-label control-label p-0 p-r-5" style="margin-top: 0">
                        {{ $t('common.mail address') }} <span class="color-active">＊</span></label>
                      <div class="col-md-8 form-group m-b-0 p-0">
                        <div class="">
                          <ValidationObserver ref="update_emails" v-slot="{ passes, valid, validated }">
                            <div class="m-t-5" v-for="(email, key) in entry.emails">
                              <AppInput hidden :name="'update_emails_force_reject_' + key"
                                        v-model="validate.update_emails_force_reject[key]"></AppInput>
                              <AppInput col-input="12" :name="'email' + key" type="email"
                                        :rules="V_EMAIL_REQUIRED + '|email_unique:@update_emails_force_reject_' + key"
                                        boundary-class="b-b-0 p-0 m-0"
                                        v-model="entry.emails[key]">
                                <template v-if="entry.emails.length > 1" v-slot:append>
                                  <div @click="entry.emails.splice(key, 1)" class="btn-tool delete"></div>
                                </template>
                              </AppInput>
                            </div>
                          </ValidationObserver>
                          <button class="btn btn-create m-t-10" type="button" @click="entry.emails.push('')">
                            メールを追加
                          </button>
                        </div>
                      </div>
                    </div>
                    <button :disabled="!valid && validated" type="submit"
                            :class="['btn inline pull-right', 'btn-complete']">
                      {{ $t('common.save') }}
                    </button>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      validate: {
        code_force_reject: false,
        update_emails_force_reject: [],
      },
      taxi_companies: [],
      entry: {
        emails: ['']
      },
    }
  },
  watch: {
    'entry.code': {
      handler: function (val, oldVal) {
        if (val && val.length) {
          this.validate.code_force_reject = !this.validateTaxiCode();
          setTimeout(() => this.$refs.code.validate(), 100)
        }
      },
      deep: true
    },
    'entry.emails': {
      handler: function (emails, oldEmails) {
        if (emails && emails.length) {
          this.validateTaxiEmail();
        }
      },
    },
    deep: true
  },
  mounted() {
    this.getEntries();
  },
  methods: {
    getEntries() {
      this.$request.get(this.$consts.API.TAXI.TAXI_COMPANY_SHOW).then(res => {
        if (!res.hasErrors()) {
          this.entry = res.data.data
          this.taxi_companies = res.data.taxi_companies
        }
      })
    },
    async updateInfo() {
      const res = await this.$request.post(this.$consts.API.TAXI.TAXI_COMPANY_UPDATE, this.entry)
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.getEntries();
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_basic_info.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    validateTaxiCode() {
      var result = true;
      _.forEach(this.taxi_companies, taxi => {
        if (taxi.id != this.entry.id && this.entry.code == taxi.code) {
          result = false;
          return false;
        }
      })
      return result;
    },
    validateTaxiEmail() {
      var taxiCompanies = this.taxi_companies
      if (!taxiCompanies) {
        return;
      }
      _.forEach(this.entry.emails, (email, index) => {
        if (!email || !email.length) {
          return;
        }
        var result = true;
        if (_.countBy(this.entry.emails, emailCheck => email.toLowerCase() === emailCheck.toLowerCase()).true > 1) {
          result = false;
        } else {
          _.forEach(taxiCompanies, taxi => {
            var taxiEmails = taxi.emails.map(email => email.toLowerCase())
            if (taxi.id != this.entry.id && taxiEmails.includes(email.toLowerCase())) {
              result = false;
              return false;
            }
          })
        }
        this.validate.update_emails_force_reject[index] = !result;
        setTimeout(() => this.$refs.update_emails.validate(), 100)
      })
    }
  }
}
</script>
